"use client";

import React, { useEffect } from "react";
import { usePathname } from "next/navigation";

const HeaderScrollBehaviour = () => {
  const pathname = usePathname();

  useEffect(() => {
    const header = document.getElementById("mainHeader");
    const headerLogo = document.getElementById("headerLogo");
    const languageSelector = document.getElementById("languageSelectorBtn");
    const handleScroll = () => {
      if (screen.width < 1280) {
        const scrollThreshold = 100;
        const isHeaderScrolled = window.scrollY > scrollThreshold;
        if (isHeaderScrolled) {
          // Add grey bg for tablet, since logo is white when header is sticky
          if (!pathname.includes("privacy-policy")) {
            header?.classList.add("md:bg-colorPrimary-400", "xl:bg-transparent");
          } else if (screen.width < 768) {
            header?.classList.remove("bg-colorPrimary-400");
          }
          headerLogo?.classList.add(
            "opacity-0",
            "md:opacity-100",
            "pointer-events-none",
            "md:pointer-events-auto",
          );
          languageSelector?.classList.add(
            "opacity-0",
            "md:opacity-100",
            "pointer-events-none",
            "md:pointer-events-auto",
          );
        } else {
          if (!pathname.includes("privacy-policy")) {
            header?.classList.remove("md:bg-colorPrimary-400", "xl:bg-transparent");
          } else if (screen.width < 768) {
            header?.classList.add("bg-colorPrimary-400");
          }
          headerLogo?.classList.remove(
            "opacity-0",
            "md:opacity-100",
            "pointer-events-none",
            "md:pointer-events-auto",
          );
          languageSelector?.classList.remove(
            "opacity-0",
            "md:opacity-100",
            "pointer-events-none",
            "md:pointer-events-auto",
          );
        }
      }
    };

    if (pathname.includes("privacy-policy")) {
      // If pathname contains "privacy-policy", set specific background color
      header?.classList.add("bg-colorPrimary-400");
    } else {
      header?.classList.remove("bg-colorPrimary-400");
    }
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [pathname]);

  return <></>;
};

export default HeaderScrollBehaviour;
