"use client";

import { useEffect } from "react";
import Script from "next/script";
import { usePathname, useSearchParams } from "next/navigation";
import { useCurrentLocale } from "next-i18n-router/client";
import { Config } from "next-i18n-router/dist/types";

import { pageview } from "@/lib/gtm";
import vars from "@/config/vars";
import i18nConfig from "../../../i18nConfig";

export default function GoogleTagManager({ enabled }: { enabled: boolean }) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const currentLocale = useCurrentLocale(i18nConfig as Config);
  const lang = currentLocale || i18nConfig.defaultLocale;

  useEffect(() => {
    if (pathname) {
      pageview(pathname, lang);
    }
  }, [lang, pathname, searchParams]);

  if (!enabled) {
    return null;
  }

  return (
    <>
      <Script
        id="gtm-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer', '${vars.GTMID}');
  `,
        }}
      />
    </>
  );
}
