import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.5_@opentelemetry+api@1.8.0_@playwright+test@1.44.1_react-dom@18._g5otwj6zqujlnvffirl7pzkkvy/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.5_@opentelemetry+api@1.8.0_@playwright+test@1.44.1_react-dom@18._g5otwj6zqujlnvffirl7pzkkvy/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.5_@opentelemetry+api@1.8.0_@playwright+test@1.44.1_react-dom@18._g5otwj6zqujlnvffirl7pzkkvy/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Lora\",\"arguments\":[{\"weight\":[\"700\"],\"style\":[\"normal\",\"italic\"],\"subsets\":[\"latin\"],\"variable\":\"--font-lora\",\"display\":\"swap\"}],\"variableName\":\"lora\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.5_@opentelemetry+api@1.8.0_@playwright+test@1.44.1_react-dom@18._g5otwj6zqujlnvffirl7pzkkvy/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Barlow\",\"arguments\":[{\"weight\":[\"300\",\"600\"],\"style\":[\"normal\",\"italic\"],\"subsets\":[\"latin\"],\"variable\":\"--font-barlow\",\"display\":\"swap\"}],\"variableName\":\"barlow\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/mapbox-gl@3.4.0/node_modules/mapbox-gl/dist/mapbox-gl.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/react-datocms@6.0.5_@types+react-dom@18.3.0_@types+react@18.3.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/react-datocms/dist/esm/Image/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/react-datocms@6.0.5_@types+react-dom@18.3.0_@types+react@18.3.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/react-datocms/dist/esm/useQuerySubscription/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/react-datocms@6.0.5_@types+react-dom@18.3.0_@types+react@18.3.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/react-datocms/dist/esm/useSiteSearch/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/react-datocms@6.0.5_@types+react-dom@18.3.0_@types+react@18.3.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/react-datocms/dist/esm/VideoPlayer/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/icons/menu_icon.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/CustomIconifyIcon/CustomIconifyIcon.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/DraftModeMessage/DraftModeMassage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/GoogleTagManager/GoogleTagManager.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Header/HeaderScrollBehaviour/HeaderScrollBehaviour.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/InfoPopup/InfoPopup.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/LanguageSelector/LanguageSelector.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Sheet","SheetTrigger","SheetContent","SheetClose"] */ "/vercel/path0/src/components/ui/sheet.tsx");
