import ItalianoFlagIcon from "@/components/Icons/LanguageFlagIcons/ItalianoFlagIcon";
import EnglishFlagIcon from "@/components/Icons/LanguageFlagIcons/EnglishFlagIcon";
import SpanishFlagIcon from "@/components/Icons/LanguageFlagIcons/SpanishFlagIcon";
import ChineseFlagIcon from "@/components/Icons/LanguageFlagIcons/ChineseFlagIcon";

export const LocalesMapping: Record<string, Record<string, string | React.FC>> = {
  it: {
    language: "Italiano",
    flag: ItalianoFlagIcon,
  },
  en: {
    language: "English",
    flag: EnglishFlagIcon,
  },
  es: {
    language: "Spanish",
    flag: SpanishFlagIcon,
  },
  zh: {
    language: "Chinese",
    flag: ChineseFlagIcon,
  },
  // fr: {
  //   language: "Français",
  //   flag: FrancaisFlagIcon,
  // },
  // de: {
  //   language: "Deutsch",
  //   flag: DeutschFlagIcon,
  // },
};
