"use client";
import React, { useState } from "react";
import CustomIconifyIcon from "../CustomIconifyIcon/CustomIconifyIcon";

function DraftModeMessage() {
  const [close, setClose] = useState(true);
  return (
    <>
      {close ? (
        <div className=" w-full md:h-12 lg:h-10 bg-red-800 bg-opacity-75 fixed bottom-0 right-0 z-[70]">
          <div className="w-full relative">
            <div className="flex w-full p-2 m-0 flex-col md:flex-row justify-center items-center gap-6">
              <CustomIconifyIcon name={"mdi:eye"} className={"w-10 h-6 text-white"} />
              <p className="text-white text-center text-base leading-tight font-bold">
                This website is currently viewed in Preview mode and may not reflect the content of
                the latest build
              </p>
            </div>
            <button
              onClick={() => {
                setClose(false);
              }}
              className="absolute right-0 top-0"
            >
              <CustomIconifyIcon name={"mdi:close"} className={"w-5 h-5 text-white "} />
            </button>
          </div>
        </div>
      ) : (
        <button
          className={
            "bg-red-800 w-14 h-2.5 flex justify-center items-center bg-opacity-75 fixed bottom-16 right-2 md:bottom-2 md:left-2 z-[70] rounded-lg"
          }
          onClick={() => {
            setClose(true);
          }}
        >
          <CustomIconifyIcon name={"mdi:eye"} className={"w-10 h-6 text-white"} />
        </button>
      )}
    </>
  );
}

export default DraftModeMessage;
